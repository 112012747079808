import React from 'react'
import PropTypes from 'prop-types'
import HeroBannerContent from './HeroBannerContent'
import Img from '../../../shared/Img'

const HomeHeroBanner = ({
  title,
  text,
  name,
  content,
  isDefaultLang,
  nameSuperscript,
}) => (
  <>
    <div
      className="relative md:min-h-98"
      data-testid="main-div"
      id="hero-banner"
    >
      <Img
        src="/next-assets/alejandra_hero_1599x531.webp"
        alt="woman with long brown hair smiling because she has found debt relief"
        priority={true}
        isBackgroundImage={true}
        objectFit="cover"
        className="h-34 w-full sm:h-56 md:h-full"
        imgClassName="object-top"
        layout="responsive"
      />
      <div className="grid h-full md:min-h-98 md:grid-cols-8">
        <div
          className="my-auto flex justify-end sm:min-h-56 md:col-span-3 md:my-0 md:ml-2 md:flex-wrap md:content-end md:justify-center md:bg-none lg:col-span-4 xl:col-span-3 xl:justify-end"
          data-testid="blockquote-div"
        >
          <blockquote className="mb-6 mr-2 w-48 text-right text-gray-900 md:mb-8 md:ml-1 md:text-left lg:mb-0 lg:w-64 xl:mb-2">
            {title ? (
              <h3
                className="screen-reader-text mb-1 mt-2 text-xs md:text-sm"
                id="hero-blockquote-title"
              >
                {title}
              </h3>
            ) : null}
            <p className="text-xs font-semibold italic leading-relaxed md:pb-2 lg:pr-8">
              {text}
            </p>
            <cite className="text-12 not-italic lg:text-sm">
              {name}
              {nameSuperscript}
            </cite>
          </blockquote>
        </div>
        <div className="my-auto bg-white px-5 pt-1 pb-0 text-left md:col-span-5 md:bg-transparent md:py-2 lg:col-span-4 lg:p-5">
          <HeroBannerContent content={content} isDefaultLang={isDefaultLang} />
        </div>
      </div>
    </div>
  </>
)

HomeHeroBanner.defaultProps = {
  title: '',
  isDefaultLang: true,
  nameSuperscript: '',
}

HomeHeroBanner.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  isDefaultLang: PropTypes.bool,
  nameSuperscript: PropTypes.string,
}

export default HomeHeroBanner
